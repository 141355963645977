import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    accessToken: String,
    centerLat: { type: Number, default: 41.82215987922715 },
    centerLng: { type: Number, default: -6.759994027894192 },
    mapStyle: { type: String, default: 'mapbox://styles/mapbox/satellite-streets-v11' },
    markerColor: { type: String, default: '#059669' },
    markerLat: Number,
    markerLng: Number,
    zoom: { type: Number, default: 16 }
  }

  connect() {
    mapboxgl.accessToken = this.accessTokenValue

    this.map = new mapboxgl.Map({
      container: this.element,
      style: this.mapStyleValue,
      center: [this.centerLngValue, this.centerLatValue],
      zoom: this.zoomValue
    });

    this.map.on('load', () => {
      if (!Number.isNaN(this.markerLatValue) && !Number.isNaN(this.markerLngValue)) {
        this.marker = new mapboxgl.Marker({ color: this.markerColorValue })
          .setLngLat([this.markerLngValue, this.markerLatValue])
          .addTo(this.map);
      }
    })
  }
}

