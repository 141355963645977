import { Controller } from "@hotwired/stimulus"
import { useClickOutside, useTransition } from 'stimulus-use'

export default class extends Controller {
  static targets = ['content']

  connect() {
    useClickOutside(this)
    useTransition(this, { element: this.contentTarget })
  }

  toggle() {
    this.toggleTransition();
  }
}
